import tinymce from 'tinymce';

import 'tinymce/icons/default';

import 'tinymce/themes/silver';
import 'tinymce/models/dom';

import 'tinymce/skins/ui/oxide/skin.css';

import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/link';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/media';
import 'tinymce/plugins/autoresize';

import contentUiSkinCss from 'tinymce/skins/ui/oxide/content.css?inline';
import contentCss from 'tinymce/skins/content/default/content.css?inline';

import { tinyMceHTMLCleaner } from "@/entrypoints/tiny-mce-html-cleaner";
import resized from "@/javascript/components/events/resized";

const getConfig = (options = {}) => {
  const tinymceOptions = JSON.parse(options.tinymceOptions || '{}')

  const customContentCss = document.querySelector('meta[name="tinymce_custom_css"]').content
  const config = {
    license_key: 'gpl',
    menubar: false,
    plugins: "advlist autolink lists link charmap preview anchor searchreplace code media autoresize",
    paste_block_drop: false,
    max_height: 600,
    min_height: 50,
    toolbar: "undo redo | blocks | bold italic | bullist numlist outdent indent | link",
    toolbar_mode: "sliding",
    content_css: false,
    skin: false,
    content_style: contentUiSkinCss.toString() + '\n' + contentCss.toString(),
    readonly: tinymceOptions.disabled || false,
    target: options.target,
    content_css: customContentCss
  }

  return config
}

/* Initialize TinyMCE */
export function render (content) {
  const tinymceElements = [...content.querySelectorAll('[data-tinymce]')]
  const promises = tinymceElements
    .filter((elem) => !elem.tinyMceInitated )
    .map((elem) => {
      elem.tinyMceInitated = true
      elem.tinyMceHTMLCleaner = new tinyMceHTMLCleaner()
      try {
        tinymce.remove(`#${elem.id}`)
      } catch (error) {}
      const config = getConfig({target: elem, tinymceOptions: elem.dataset.tinymceOptions})
      config.paste_preprocess = (editor, args) => {
        args.content = elem.tinyMceHTMLCleaner.clean(args.content)
        return args.content
      }
      tinymce.init(config)
        .then((elems) => elems)
        .then((editors) => {
          editors.forEach((editor) => {
            editor.getWin().addEventListener('resize', () => {
              resized(editor.iframeElement)
            })
          })
        })
    })

  return Promise.all(
    promises
  )
}
